<template>
  <div>
    <Section1 :keyWord="'Consigue 1.000€ hoy mismo, aunque estés en ASNEF'" />
    <div class="texto-landing">
      <!-- Escribe aquí gaston, gracias :) -->
      <h2 class="title-h1 text-center pb-4"><strong>Préstamo 1000€ asnef HOY MISMO</strong></h2>

      <div class="mb-5 d-flex align-items-center justify-content-center">
        <div class="video-container">
          <iframe
                  src="https://www.youtube.com/embed/RfU8TjnReTU?si=a0aVzJ6EBMhy8u5G"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen>
          </iframe>
        </div>
      </div>
      <p>Rellenar un simple formulario, recibir una propuesta personalizada y contar con el dinero en tu cuenta en menos de 24 horas completando todo el proceso de manera 100% online. Así es el <strong>préstamo 1000 euros asnef</strong> de Ibancar.</p>

      <h3 class="naranja"><strong>Préstamo de 1000 euros al instante</strong></h3>
      <p>La denominación de <strong>préstamo 1000 euros asnef</strong> al instante hace referencia  a que no necesitas nada más que una conexión a internet para acceder al crédito.</p>
      <p>El proceso se completa en cuestión de minutos y puede ayudarte a satisfacer un pago urgente o iniciar un proyecto personal sin importar que hayas sido incluido en los ficheros de impagados de asnef.</p>

      <h3 class="naranja"><strong>Oferta de préstamos de 1000 euros</strong></h3>
      <p>La  oferta de <strong>préstamo 1000 euros asnef</strong> de Ibancar, a diferencia de la de otras entidades que ofrecen este tipo de soluciones en el mercado, es mucho más flexible en cuanto a condiciones.</p>
      <p>Además, incorpora el asesoramiento personalizado de un gestor que te acompaña durante todo el proceso resolviendo cualquier duda que pueda surgirte.</p>

      <h3 class="naranja"><strong>¿Cuándo pedir un préstamo 1000 euros?</strong></h3>
      <p>El préstamo de 1000 euros al instante es perfecto para satisfacer un pago urgente, para iniciar un proyecto personal que necesita liquidez en sus inicios o para pagar una formación que te pueda facilitar una titulación para mejorar tu currículum académico.</p>

      <h3 class="naranja"><strong>Préstamo 1000 euros a plazos</strong></h3>
      <p>Lo mejor del <strong>préstamo 1000 euros asnef</strong> es que puedes pagarlo a plazos. Cuando solicites la cantidad que necesitas recibirás una propuesta con todos los detalles sobre la periodicidad y cuantía de los pagos adaptada a tu perfil y circunstancias.</p>

      <h3 class="naranja"><strong>Requisitos para solicitar un préstamo de 1000 euros online</strong></h3>
      <p>En Ibancar, solicitar un <strong>préstamo 1000 euros asnef</strong> online es muy sencillo. ¿La documentación que necesitas?</p>
      <ul>
          <li>DNI</li>
          <li>Justificante de ingresos</li>
          <li>Justificante de número de cuenta en el que el solicitante aparezca como titular</li>
      </ul>

      <h3 class="naranja"><strong>Tipos de préstamo de mil euros</strong></h3>
      <p>Una de las mejores soluciones en el mercado de los préstamos 1000 euros asnef es el crédito coche Ibancar, un préstamo que utiliza tu coche como aval sin ningún tipo de restricción a la hora de seguir disfrutándolo.</p>

      <h3 class="naranja"><strong>Préstamo 1000 euros con ASNEF</strong></h3>
      <p>El fichero de impagados de asnef es uno de los impedimentos más habituales a la hora de solicitar un crédito en una entidad bancaria tradicional.</p>
      <p>El <strong>préstamo 1000 euros asnef</strong> de Ibancar facilita el acceso a un préstamo urgente sin tener en cuenta la inclusión del solicitante en este tipo de listados.</p>

      <h3 class="naranja"><strong>¿Dónde pedir un préstamo de mil euros?</strong></h3>
      <p>Ibancar te permite pedir un préstamo de mil euros online y completar todo el proceso en cuestión de minutos sin ni siquiera levantarte del sofá.</p>

      <h3 class="naraja"><strong>Préstamo de 1000 euros sin dar explicaciones</strong></h3>
      <p>Ya no tendrás que sentarte nunca más en tu entidad bancaria para entrevistarte, reunir y firmar decenas de documentos ni explicar por qué has sido incluido en un fichero como el de asnef.</p>
      <p> El <strong>préstamo 1000 euros asnef</strong> es respetuoso con tu privacidad y te ofrece todas las facilidades para que puedas acceder a tu crédito de una forma sencilla y ágil.</p>

      <!-- Boton Préstamo -->
      <div class="d-flex flex-row justify-content-center align-items-center">
          <div class="flex-column pt-5">
              <router-link to="/" class="btn btn-orange text-center p-2">Solicitar préstamo</router-link>
          </div>
      </div>
    </div>
    <Section2 />
    <CreditoCocheS4 /> <!-- acordeón de preguntas -->
    <div class="relleno"></div>
    
    <div class="cards-prestamo">
      <div class="d-flex flex-row justify-content-center align-items-center flex-wrap pt-2">
        <div class="padding">
            <h2 class="title-h1 text-center">Préstamo por tu coche</h2>
        </div>
      </div>

      <div class="d-flex flex-row justify-content-center flex-wrap pt-3">
        <div class="card m-4">
          <a href="../prestamos-asnef" class="no">
            <div class="card-body">
              <h5 class="card-title naranja inline">Préstamo con ASNEF</h5>
                <span class="caret-sign">
                  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.8 0L0 1.86667L5.91429 8L0 14.1333L1.8 16L9.51429 8L1.8 0Z" fill="#686B6F"/>
                  </svg>
                </span>
              <p class="card-text pl-1 pt-2">Obtén un préstamo con ASNEF. Te ayudamos a conseguir el dinero que necesitas, incluso si estás en ASNEF.</p>
            </div>
          </a>
        </div>
      </div>

      <div class="d-flex flex-row justify-content-center align-items-center">
          <div class="flex-column p-5">
              <router-link to="/" class="btn btn-orange text-center p-2">Solicitar préstamo</router-link>
          </div>
      </div>

    </div>
    <div class="relleno"></div>
    <!-- No te pases de aquí gaston, gracias 2.0 :) -->
  </div>
</template>

<script>
// @ is an alias to /src
import Section1 from '@/components/Home/Section1.vue'
import { useMeta } from 'vue-meta'
import store from "@/store";
import Section2 from '@/components/Home/Section2.vue'
import CreditoCocheS4 from '@/components/CreditoCoche/CreditoCocheS4.vue'

// @ is an alias to /src
export default {
  name: 'Prestamo1000EurosAsnef',
  components: {
    Section1,
    Section2,
    CreditoCocheS4
  },
  setup() {

    useMeta({
      title: 'Préstamo 1000€ asnef HOY MISMO | Ibancar',
      meta:[
        {name:'robots', content:store.state.dominio == 'ibancar.com' ? 'index' : 'noindex'},
        {name:'description', content:'Rellenar un simple formulario, recibir una propuesta personalizada y contar con el dinero en tu cuenta en menos de 24 horas completando todo el proceso de manera 100% online. Así es el préstamo 1000 euros asnef de Ibancar.'},
        {name:'keywords', content:'préstamo 1000 euros asnef'}
      ]
    })
    }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/variables.scss';
.naranja {
  color: $orange;
  font-weight: bold;
}
.texto-landing {
  padding: 3em 15em;
  line-height: 2em;
}
.card {
  width: 22rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: 10rem;
}
.inline {
  display: inline;
}
.caret-sign, .caret-sign-city {
  margin-left: 25px;
  vertical-align: top;
  float: right;
}
.caret-sign-city {
  position: relative;
  top: -27px;
}
a.no {
  text-decoration: none;
  color: #333;
}
a.no:hover {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: relative;
  bottom: 1px;
  height: 10rem;
}
.relleno {
  height: 50px;
}

@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

@include media-breakpoint-between(xs,sm) {
  .texto-landing {
    padding: 1.5em 2em;
  }
  .card {
    height: 100%;
  }
  a.no:hover {
    height: 100%;
  }
  .title-h1 {
    font-size: 32px;
  }
  .naranja {
    font-size: 24px;
  }
}

@include media-breakpoint-between(md,lg) {
  .texto-landing {
    padding: 1.5em 5em;
  }
}

@include media-breakpoint-between(xl,xxl) {

}
</style>